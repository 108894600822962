<template>
  <div class="info-place">
    <PlaceInfo :poiId="$route.params.poiId" :userData="userData" />
  </div>
</template>

<script>
import { Settings } from 'luxon'
import PlaceInfo from '@/components/PlaceInfo.vue'
export default {
  name: 'InfoPlace',
  components: {
    PlaceInfo
  },
  props: {
    userData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {}
  },
  mounted () {
    const self = this
    if ('lang' in self.$route.query || 'locale' in self.$route.query) {
      self.$i18n.locale = (self.$route.query.lang || self.$route.query.locale) === 'th' ? 'th' : 'en'
      localStorage.lang = self.$i18n.locale
      Settings.defaultLocale = self.$i18n.locale
    } else {
      if (localStorage.lang) {
        self.$i18n.locale = localStorage.lang
        Settings.defaultLocale = localStorage.lang
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.info-place {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
</style>
