<template>
  <div class="place-info">
    <div class="header">
      <button class="back ripple" @click="onClickBack">
        <i class="material-icons-round">arrow_back</i>
      </button>
      <div class="title">{{ title }}</div>
      <button ref="toggle-action-list" class="toggle-action-list ripple" @click="onClickAction" v-if="!isDeletedStatus && !isEmptyPlaceDetail && !isRejectedStatus">
        <i class="material-icons-round">more_vert</i>
      </button>
    </div>
    <div ref="action-list" class="action-list" v-if="isShowActionList && !isLoading">
      <button class="action ripple" @click="onClickShare">
        <i class="material-icons-round">share</i>
        <span>{{ $t('placeInfo.action.share') }}</span>
      </button>
      <button class="action ripple" @click="onClickMap">
        <i class="material-icons-round">map</i>
        <span>{{ $t('placeInfo.action.map') }}</span>
      </button>
      <button class="action ripple" @click="onClickDirection">
        <i class="material-icons-round">navigation</i>
        <span>{{ $t('placeInfo.action.navigation') }}</span>
      </button>
      <button class="action ripple" @click="onClickFav" v-if="!isMarkFav">
        <i class="material-icons-round">star_border</i>
        <span>{{ $t('placeInfo.action.fav') }}</span>
      </button>
      <button class="action ripple" @click="onClickUnfav" v-if="isAuthenticated && isMarkFav">
        <i class="material-icons-round">star</i>
        <span>{{ $t('placeInfo.action.unFav') }}</span>
      </button>
      <button class="action ripple" @click="onClickEdit" v-if="canEdit">
        <i class="material-icons-round">edit</i>
        <span>{{ $t('placeInfo.action.edit') }}</span>
      </button>
      <button class="action ripple" @click="onClickDelete" v-if="canDelete">
        <i class="material-icons-round">delete</i>
        <span>{{ $t('placeInfo.action.delete') }}</span>
      </button>
    </div>
    <div class="loading-overlay" v-if="isLoading">
      <img src="@/assets/img/loading.gif">
    </div>
    <div ref="body" class="body" v-if="!isLoading">
      <div
        class="map-snippet ripple"
        v-if="mapSnippetWidth > 0 && !isEmptyPlaceDetail"
        @click="onClickMap"
        :style="{
          width: `${mapSnippetWidth}px`,
          height: `${mapSnippetHeight}px`,
          backgroundImage: `url('${mapSnippetUrl}')`
        }">
      </div>

      <div class="new-status notice" v-if="isNewStatus">! {{ $t('placeInfo.newStatus') }}</div>
      <div class="obsoleted-status notice" v-if="isObsoletedStatus">! {{ $t('placeInfo.obsoletedStatus') }}</div>
      <div class="deleted-status notice" v-if="isDeletedStatus || isEmptyPlaceDetail">! {{ $t('placeInfo.deletedStatus') }}</div>
      <div class="rejected-status notice" v-if="isRejectedStatus">! {{ $t('placeInfo.rejectedStatus') }}</div>

      <div class="place-name" v-if="!isEmptyPlaceDetail">{{ placeName }}</div>
      <div class="sub-place-name" v-if="!isEmptyPlaceDetail">{{ subPlaceName }}</div>
      <div class="promptpai" v-if="!isEmptyPlaceDetail">
        <iframe v-if="isPromptPai" :src="promptPaiIframe" width="119" height="26" style="border:none;overflow:hidden;margin: 6px 0px 0px 0px;" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
      </div>

      <div class="field" v-if="placeCategoryLabel">
        <div class="field-icon" @touchstart="onTouchstartIcon($event)">
          <i class="material-icons-round">category</i>
          <label>{{ $t('placeInfo.category') }}</label>
        </div>
        <div class="content">
          <span class="place-category-icon">
            <img :src="placeCategoryIconSrc">
          </span>
          <span>{{ placeCategoryLabel }}</span>
        </div>
      </div>

      <div class="field connector-list" v-if="connectorList.length > 0">
        <div class="field-icon" @touchstart="onTouchstartIcon($event)">
          <i class="material-icons-round">electrical_services</i>
          <label>{{ $t('placeInfo.connector') }}</label>
        </div>
        <div class="content">
          <ul>
            <div v-for="connector in connectorList" :key="`connector-${connector.connector_type_id}`">
              <span class="total-available">{{ connector.total_available }}</span> {{ connector.charger_type_name }} {{ connector.connector_type_name }} {{ connector.discharge_electricity }}
            </div>
          </ul>
        </div>
      </div>

      <div class="field" v-if="address">
        <div class="field-icon" @touchstart="onTouchstartIcon($event)">
          <i class="material-icons-round">place</i>
          <label>{{ $t('placeInfo.address') }}</label>
        </div>
        <div class="content">
          {{ address }} {{ address_detail? ` (${address_detail})` : ''}}
        </div>
      </div>

      <div class="field" v-if="parentPlaceName">
        <div class="field-icon" @touchstart="onTouchstartIcon($event)">
          <i class="material-icons-round">home_work</i>
          <label>{{ $t('placeInfo.parentPlace') }}</label>
        </div>
        <div class="content">
          <a @click="gotoParentPlaceInfo">{{ parentPlaceName }}</a>
        </div>
      </div>

      <div class="field" v-if="busList.length > 0">
        <div class="field-icon" @touchstart="onTouchstartIcon($event)">
          <i class="material-icons-round">directions_bus</i>
          <label>{{ $t('placeInfo.bus') }}</label>
        </div>
        <div class="content">
          <div class="bus-list">
            <span
              v-for="(bus, i) in busList"
              :key="`bus-${i}`"
              class="bus ripple"
            >{{ bus.display }}</span>
          </div>
        </div>
      </div>

      <div class="field" v-if="bookingcomUrl">
        <div class="field-icon" @touchstart="onTouchstartIcon($event)">
          <i class="material-icons-round">hotel</i>
          <label>{{ $t('placeInfo.booking') }}</label>
        </div>
        <div class="content">
          <a @click="onClickUrl(bookingcomUrl)">Booking.com</a>
        </div>
      </div>

      <div class="field" v-if="workingHoursList.length > 0">
        <div class="field-icon" @touchstart="onTouchstartIcon($event)">
          <i class="material-icons-round">schedule</i>
          <label>{{ $t('placeInfo.schedule') }}</label>
        </div>
        <div class="content">
          <div class="col">
            <div
              v-for="(workingHours, i) in workingHoursList"
              :key="`working-hours-${i}`"
              class="working-hours row"
            >
              <div class="day">{{ $t(`day.${workingHours.day}`) }}</div>
              <div class="start">{{ workingHours.start }}</div>
              <div class="to">-</div>
              <div class="end">{{ workingHours.end }}</div>
            </div>
            <div class="remark">{{ workingHoursRemark }}</div>
          </div>
        </div>
      </div>

      <div class="field" v-if="mobileList.length > 0">
        <div class="field-icon" @touchstart="onTouchstartIcon($event)">
          <i class="material-icons-round">phone_iphone</i>
          <label>{{ $t('placeInfo.mobile') }}</label>
        </div>
        <div class="content">
          <div class="col">
            <div
              v-for="(mobile, i) in mobileList"
              :key="`mobile-${i}`"
            >
              <a @click="onClickUrl(mobile.href)" >{{ mobile.display }}</a>
              <div class="contact-remark" v-if="mobile.remark">{{ mobile.remark }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="field" v-if="telList.length > 0">
        <div class="field-icon" @touchstart="onTouchstartIcon($event)">
          <i class="material-icons-round">phone</i>
          <label>{{ $t('placeInfo.phone') }}</label>
        </div>
        <div class="content">
          <div class="col">
            <div
              v-for="(tel, i) in telList"
              :key="`tel-${i}`"
            >
              <a @click="onClickUrl(tel.href)" >{{ tel.display }}</a>
              <div class="contact-remark" v-if="tel.remark">{{ tel.remark }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="field" v-if="faxList.length > 0">
        <div class="field-icon" @touchstart="onTouchstartIcon($event)">
          <i class="material-icons-round">print</i>
          <label>{{ $t('placeInfo.fax') }}</label>
        </div>
        <div class="content">
          <div class="col">
            <div
              v-for="(fax, i) in faxList"
              :key="`fax-${i}`"
            >
              <a @click="onClickUrl(fax.href)">{{ fax.display }}</a>
              <div class="contact-remark" v-if="fax.remark">{{ fax.remark }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="field" v-if="specialTelList.length > 0">
        <div class="field-icon" @touchstart="onTouchstartIcon($event)">
          <i class="material-icons-round">support_agent</i>
          <label>{{ $t('placeInfo.specialNumber') }}</label>
        </div>
        <div class="content">
          <div class="col">
            <div
              v-for="(tel, i) in specialTelList"
              :key="`tel-${i}`"
            >
              <a @click="onClickUrl(tel.href)">{{ tel.display }}</a>
              <div class="contact-remark" v-if="tel.remark">{{ tel.remark }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="field" v-if="lineList.length > 0">
        <div class="field-icon" @touchstart="onTouchstartIcon($event)">
          <div class="image" :style="{
            backgroundImage: `url('${lineLogoUrl}')`
          }"></div>
          <label>{{ $t('placeInfo.line') }}</label>
        </div>
        <div class="content">
          <div class="col">
            <div
              v-for="(line, i) in lineList"
              :key="`line-${i}`"
            >
              <span v-if="line.id">{{ line.id }}</span>
              <a v-else @click="onClickUrl('https://line.me/ti/p/' + line.link)">{{ line.link }}</a>
              <div class="contact-remark" v-if="line.remark">{{ line.remark }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="field" v-if="website">
        <div class="field-icon" @touchstart="onTouchstartIcon($event)">
          <i class="material-icons-round">public</i>
          <label>{{ $t('placeInfo.website') }}</label>
        </div>
        <div class="content">
          <a @click="onClickUrl(website)">{{ website }}</a>
        </div>
      </div>

      <div class="field" v-if="placeTagList.length > 0">
        <div class="field-icon" @touchstart="onTouchstartIcon($event)">
          <i class="material-icons-round">local_offer</i>
          <label>{{ $t('placeInfo.tag') }}</label>
        </div>
        <div class="content">
          <div class="tag-list">
            <span
              v-for="(tag, i) in placeTagList"
              :key="`tag-${i}`"
              class="tag ripple"
              @click="onClickTag(tag)"
            >{{ tag }}</span>
          </div>
        </div>
      </div>

      <div class="field full-description" v-if="descriptionHtml">
        <div class="field-icon" @touchstart="onTouchstartIcon($event)">
          <i class="material-icons-round">description</i>
          <label>{{ $t('placeInfo.descriptionHtml') }}</label>
        </div>
        <div class="content">
          <div v-html="descriptionHtml"></div>
        </div>
      </div>

      <div class="col" v-if="imageList.length > 0">
        <div class="image-label row">
          <i class="material-icons-round">image</i>
          {{ $t('placeInfo.imageLabel') }}
        </div>
        <div class="image-list row">
          <ImageWidget
            class="image"
            v-for="(image, i) in imageList"
            :key="`image-${i}`"
            :image="image"
            :index="i"
            :width="imageWidth"
            :height="imageHeight"
            :canView="true"
            :showPrevButton="i > 0"
            :showNextButton="i < (imageList.length - 1)"
            @clickPrev="onClickPrevImage(i)"
            @clickNext="onClickNextImage(i)"
          />
        </div>
      </div>

      <div class="date" v-html="date"></div>

    </div>

    <SharePlaceWidget
      :poiId="poiId"
      :lat="lat"
      :lon="lon"
      :isShareInfoPage="true"
      @close="isShowSharePlaceWidget = false"
      v-if="isShowSharePlaceWidget"
    />

    <div class="loading-overlay" v-if="isUpdatingFav || isDeleting">
      <img src="@/assets/img/loading.gif">
    </div>
  </div>
</template>

<script>
import ImageWidget from '@/components/ImageWidget.vue'
import SharePlaceWidget from '@/components/SharePlaceWidget.vue'
export default {
  name: 'PlaceInfo',
  components: {
    ImageWidget,
    SharePlaceWidget
  },
  props: {
    poiId: {
      type: String,
      required: true
    },
    userData: {
      type: Object,
      default: null
    }
  },
  computed: {
    isAuthenticated () {
      const self = this
      const userSession = self.fetchUserInfo()
      return userSession || self.userData
      // return self.longdoComAPI.getUserInfo() || self.userData || self.user
    },
    isMarkFav () {
      const self = this
      return self.placeDetail?.marked_as_favorite || false
    },
    canEdit () {
      const self = this
      return self.placeDetail?.permission?.edit || false
    },
    canDelete () {
      const self = this
      return self.placeDetail?.permission?.delete || false
    },
    lineLogoUrl () {
      return require('@/assets/img/line_logo.png')
    },
    isNewStatus () {
      const self = this
      return self.placeDetail?.status === 'N'
    },
    isObsoletedStatus () {
      const self = this
      return self.placeDetail?.status === 'O'
    },
    isDeletedStatus () {
      const self = this
      return self.placeDetail?.status === 'D'
    },
    isRejectedStatus () {
      const self = this
      return self.placeDetail?.status === 'R'
    },
    isEmptyPlaceDetail () {
      const self = this
      return !('status' in (self.placeDetail || {}))
    },
    isPromptPai () {
      const self = this
      return !self.isObsoletedStatus && self.poiId[0] === 'A' && self.poiId[1] !== '0'
    },
    title () {
      const self = this
      return (self.placeDetail || {})[`name_${self.$i18n.locale}`] || ''
    },
    mapSnippetHeight () {
      return 150
    },
    lat () {
      const self = this
      if (!self.placeDetail) {
        return 0
      }
      return self.placeDetail?.location?.latitude || 0
    },
    lon () {
      const self = this
      if (!self.placeDetail) {
        return 0
      }
      return self.placeDetail?.location?.longitude || 0
    },
    mapSnippetUrl () {
      const self = this
      if (!self.placeDetail) {
        return ''
      }
      return `https://mmmap15.longdo.com/mmmap/snippet/?lat=${self.lat}&long=${self.lon}&zoom=16&width=${self.mapSnippetWidth}&height=${self.mapSnippetHeight}&HD=1&locale=${self.$i18n.locale}&pinmark`
    },
    placeName () {
      const self = this
      return (self.placeDetail || {})[`name_${self.$i18n.locale}`] || ''
    },
    subPlaceName () {
      const self = this
      return (self.placeDetail || {})[`name_${self.$i18n.locale === 'en' ? 'th' : 'en'}`] || ''
    },
    promptPaiIframe () {
      const self = this
      const id = self.poiId.replace('A', '')
      return `https://promptpai.com/ws/promptpai-link?pp_id=${id}&size=normal&button_style=button&theme=light&locale=${self.$i18n.locale}`
    },
    date () {
      const self = this
      const createdTimestamp = (self.placeDetail || {}).created_timestamp || ''
      const uid = (self.placeDetail || {}).uid || ''
      const isShowCreator = (self.placeDetail?.permission?.edit || false) && (self.placeDetail?.permission?.delete || false)
      const updatedTimestamp = (self.placeDetail || {}).updated_timestamp || ''
      if (createdTimestamp || updatedTimestamp) {
        var dateList = []
        if (createdTimestamp) {
          const createdDate = new Date(Number(createdTimestamp) * 1000)
          const createdDateString = createdDate.toLocaleString(self.$i18n.locale, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          })
          dateList.push(`${self.$t('placeInfo.createdDate')}: ${createdDateString}`)
        }
        if (isShowCreator && uid) {
          const creatorName = self.placeDetail?.datasource?.source || ''
          dateList.push(`${self.$t('placeInfo.createdBy')} <a @click="onClickUrl(${process.env.VUE_APP_LONGDO_MAP_USER_INFO}${uid})">${creatorName}</a>`)
        }
        if (updatedTimestamp) {
          const updatedDate = new Date(Number(updatedTimestamp) * 1000)
          const updatedDateString = updatedDate.toLocaleString(self.$i18n.locale, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          })
          dateList.push(`${self.$t('placeInfo.updatedDate')}: ${updatedDateString}`)
        }
        return dateList.join(' ')
      } else {
        return ''
      }
    },
    placeCategoryIconSrc () {
      const self = this
      return self.placeDetail?.iconfile || ''
    },
    placeCategoryLabel () {
      const self = this
      return (self.placeDetail || {})[`place_type_${self.$i18n.locale}`]
    },
    address () {
      const self = this
      return (self.placeDetail || {})[`address_${self.$i18n.locale}`] || ''
    },
    address_detail () {
      const self = this
      return (self.placeDetail || {})[`address_detail_${self.$i18n.locale}`] || ''
    },
    parentPlaceName () {
      const self = this
      return (self.placeDetail || {})[`parent_name_${self.$i18n.locale}`] || ''
    },
    bookingcomUrl () {
      const self = this
      return self.placeDetail?.bookingcom_url || ''
    },
    workingHoursList () {
      const self = this
      return (self.placeDetail?.working_hours_list || {}).hours || []
    },
    workingHoursRemark () {
      const self = this
      return (self.placeDetail?.working_hours_list || {}).remark || ''
    },
    busList () {
      const self = this
      return (self.placeDetail?.bus_routes || []).map(bus => {
        return {
          ...bus,
          display: `${bus.name} ${bus[`description_${self.$i18n.locale}`]}`
        }
      })
    },
    mobileList () {
      const self = this
      return (self.placeDetail?.contact_list || []).filter(contact => contact.type === 'M').map(mobile => {
        return {
          href: `tel:${mobile.country_code ? '+' : ''}${mobile.country_code}${mobile.area_code}${mobile.tel_no}`,
          display: `${mobile.country_code ? '+' : ''}${mobile.country_code}${mobile.area_code} ${mobile.tel_no.substring(0, 3)} ${mobile.tel_no.substring(3, mobile.tel_no.length)}`,
          ...mobile
        }
      })
    },
    telList () {
      const self = this
      return (self.placeDetail?.contact_list || []).filter(contact => contact.type === 'T').map(tel => {
        return {
          href: `tel:${tel.country_code ? '+' : ''}${tel.country_code}${tel.area_code}${tel.tel_no}`,
          display: `${tel.country_code ? '+' : ''}${tel.country_code}${tel.area_code} ${tel.tel_no.substring(0, 3)} ${tel.tel_no.substring(3, tel.tel_no.length)}${tel.tel_no_to ? '-' : ''}${tel.tel_no_to} ${tel.ext ? self.$t('telInput.ext') : ''} ${tel.ext}`,
          ...tel
        }
      })
    },
    faxList () {
      const self = this
      return (self.placeDetail?.contact_list || []).filter(contact => contact.type === 'F').map(fax => {
        return {
          href: `tel:${fax.country_code ? '+' : ''}${fax.country_code}${fax.area_code}${fax.tel_no}`,
          display: `${fax.country_code ? '+' : ''}${fax.country_code}${fax.area_code} ${fax.tel_no.substring(0, 3)} ${fax.tel_no.substring(3, fax.tel_no.length)}`,
          ...fax
        }
      })
    },
    specialTelList () {
      const self = this
      return (self.placeDetail?.contact_list || []).filter(contact => contact.type === 'S').map(tel => {
        return {
          href: `tel:${tel.tel_no}`,
          display: `${tel.tel_no}${tel.tel_no_to ? '-' : ''}${tel.tel_no_to} ${tel.ext ? self.$t('telInput.ext') : ''} ${tel.ext}`,
          ...tel
        }
      })
    },
    lineList () {
      const self = this
      return (self.placeDetail?.contact_list || []).filter(contact => contact.type === 'L').map(line => {
        return {
          id: line.line_id,
          link: line.line_link,
          ...line
        }
      })
    },
    website () {
      const self = this
      return self.placeDetail?.website || ''
    },
    placeTagList () {
      const self = this
      return self.placeDetail?.tags || []
    },
    connectorList () {
      const self = this
      let connectorList = self.placeDetail?.custom_details?.list_connector
      const isMg = self.placeDetail?.custom_details?.current_ac || self.placeDetail?.custom_details?.current_dc || false
      const peaVoltaHtml = self.placeDetail?.custom_details?.description
      if (connectorList) {
        connectorList.forEach(connector => {
          connector.discharge_electricity = connector.discharge_electricity.replace('กำลังไฟฟ้า ', '')
        })
      } else if (isMg) {
        connectorList = []
        if (self.placeDetail?.custom_details?.current_ac) {
          connectorList.push({
            connector_type_id: 1,
            charger_type_name: 'AC',
            connector_type_name: '',
            discharge_electricity: '',
            total_available: self.placeDetail.custom_details.current_ac
          })
        }
        if (self.placeDetail?.custom_details?.current_dc) {
          connectorList.push({
            connector_type_id: 2,
            charger_type_name: 'DC',
            connector_type_name: '',
            discharge_electricity: '',
            total_available: self.placeDetail.custom_details.current_dc
          })
        }
      } else if (peaVoltaHtml) {
        connectorList = []
        const placeholder = document.createElement('div')
        placeholder.innerHTML = peaVoltaHtml
        placeholder.querySelectorAll('.charge-container > .charge > div:first-child').forEach((el, index) => {
          connectorList.push({
            connector_type_id: index,
            charger_type_name: el.childNodes[0].textContent,
            connector_type_name: '',
            discharge_electricity: el.childNodes[1].innerHTML.replace('(', '').replace(')', ''),
            total_available: 1
          })
        })
      }
      return connectorList || []
    },
    descriptionHtml () {
      const self = this
      return (self.placeDetail || {})[`description_${self.$i18n.locale}`]
    },
    imageWidth () {
      const self = this
      return self.imageSize + 'px'
    },
    imageHeight () {
      const self = this
      return self.imageSize + 'px'
    },
    imageList () {
      const self = this
      return (self.placeDetail?.images || []).map(image => {
        return {
          file: null,
          original: image.imagepath,
          preview: `${image.imagepath}/${self.imageSize * 2}`,
          comment: image.description,
          orientation: -1
        }
      })
    }
  },
  data () {
    return {
      user: null,
      mapSnippetWidth: 0,
      imageSize: 0,
      placeDetail: null,
      isShowActionList: false,
      isLoading: false,
      isShowSharePlaceWidget: false,
      isUpdatingFav: false,
      isDeleting: false,
      animateLabelTask: {}
    }
  },
  mounted () {
    const self = this
    window.addEventListener('resize', self.onWindowResize)
    window.addEventListener('click', self.clickOnWindow)
    self.$root.$on('loginComplete', self.onLoginComplete)
    self.$root.$on('loginFormClose', self.onLoginFormClose)

    self.mapSnippetWidth = self.$refs.body.clientWidth
    self.calImageSize()
    self.getDetail()
  },
  methods: {
    async onClickUrl (url) {
      const self = this
      if (self.isOnMobileApp) {
        try {
          await self.lji.openUrl({
            url: url
          })
        } catch (error) {
          console.log(error)
        }
      } else {
        window.open(url, '_blank').focus()
      }
    },
    async fetchUserInfo () {
      const self = this
      const userSession = await self.utility.getUserSession()
      return userSession
    },
    onLoginComplete (user) {
      const self = this
      self.user = {
        ...user,
        username: user.name,
        display_name: user.displayname
      }
      self.longdoAccount.closePrettyPhoto()
      self.getDetail()
      self.onClickFav()
    },
    onLoginFormClose () {
      // const self = this
    },
    onClickBack () {
      const self = this
      if (window.history.length <= 2) {
        self.$router.replace({
          name: 'Main',
          hash: '',
          params: {},
          query: {}
        })
      } else {
        this.$router.back()
      }
    },
    onClickAction () {
      const self = this
      self.isShowActionList = !self.isShowActionList
    },
    onClickShare () {
      const self = this
      if (typeof window.navigator !== 'undefined') {
        if (window.navigator.share) {
          window.navigator.share({
            url: `https://${process.env.VUE_APP_PRODUCT}.longdo.com/p/${self.poiId}/info`,
            title: self.$t(`main.${process.env.VUE_APP_PRODUCT}ShareThisLocationTitle`)
          })
        } else {
          self.isShowSharePlaceWidget = true
        }
      } else {
        self.isShowSharePlaceWidget = true
      }
      self.isShowActionList = false
    },
    onClickMap () {
      const self = this
      self.$router.push({
        name: 'Place',
        params: {
          poiId: self.poiId
        }
      })
      self.isShowActionList = false
    },
    onClickDirection () {
      const self = this
      self.$router.push({
        name: 'Go',
        query: {
          tlat: self.placeDetail.location.latitude,
          tlon: self.placeDetail.location.longitude
        }
      })
      self.isShowActionList = false
    },
    async onClickFav () {
      const self = this
      self.isUpdatingFav = true
      if (self.isAuthenticated) {
        let userSession = await self.utility.getUserSession()
        userSession = userSession || self.userData
        const username = userSession.name || userSession.username
        const ldtoken = userSession.longdousertoken
        const response = await self.api.updateFav({
          username,
          ldtoken,
          value: 1,
          ooiid: self.poiId
        })
        if (response.data.result.rs_code === '1') {
          self.placeDetail.marked_as_favorite = true
        }
      } else {
        self.$root.$emit('showLongdoLoginForm')
      }
      self.isShowActionList = false
      self.isUpdatingFav = false
    },
    async onClickUnfav () {
      const self = this
      self.isUpdatingFav = true
      let userSession = await self.utility.getUserSession()
      userSession = userSession || self.userData
      const username = userSession.name || userSession.username
      const ldtoken = userSession.longdousertoken
      const response = await self.api.updateFav({
        username,
        ldtoken,
        value: 0,
        ooiid: self.poiId
      })
      if (response.data.result.rs_code === '1') {
        self.placeDetail.marked_as_favorite = false
      }
      self.isShowActionList = false
      self.isUpdatingFav = false
    },
    async onClickEdit () {
      const self = this
      let userSession = await self.utility.getUserSession()
      userSession = userSession || self.userData
      self.$router.push({
        name: 'EditPlace',
        params: {
          poiId: self.$route.params.poiId,
          userData: userSession || self.userData
          // userData: self.longdoComAPI.getUserInfo() || self.userData
        }
      })
      self.isShowActionList = false
    },
    async onClickDelete () {
      const self = this
      self.isDeleting = true
      let userSession = await self.utility.getUserSession()
      userSession = userSession || self.userData
      const username = userSession.name || userSession.username
      const ldtoken = userSession.longdousertoken
      const response = await self.api.deletePlace({
        username,
        ldtoken,
        ooiid: self.poiId
      })
      if (response.data.result.rs_code === 1) {
        self.$router.replace({
          name: 'Main',
          params: {},
          query: {},
          hash: ''
        })
      }
      self.isShowActionList = false
      self.isDeleting = false
    },
    clickOnWindow (e) {
      const self = this
      if (self.isShowActionList) {
        self.isShowActionList = (self.$refs['toggle-action-list'] === e.target || self.$refs['toggle-action-list'].contains(e.target) || self.$refs['action-list'] === e.target || self.$refs['action-list'].contains(e.target))
      }
    },
    onWindowResize () {
      const self = this
      self.mapSnippetWidth = self.$refs.body.clientWidth
      self.calImageSize()
    },
    calImageSize () {
      const self = this
      const itemsPerRow = 3
      const gap = 12
      self.imageSize = (self.$refs.body.clientWidth - (gap * (itemsPerRow + 1))) / itemsPerRow
    },
    async getDetail () {
      const self = this
      self.isLoading = true
      let userSession = await self.utility.getUserSession()
      userSession = userSession || self.userData
      const username = (userSession || {}).name || undefined
      const ldtoken = (userSession || {}).longdousertoken || undefined

      const result = await self.api.getPlaceDetail({
        id: self.poiId,
        locale: self.$i18n.locale,
        username,
        ldtoken
      })
      const status = result?.data?.status?.code || 500
      if (status === 200) {
        self.placeDetail = result.data.data
      }
      self.isLoading = false
      self.$nextTick(() => {
        self.$refs.body.scrollTop = 0
      })
    },
    gotoParentPlaceInfo () {
      const self = this
      const poiId = self.placeDetail?.parent_ooiid || ''
      if (poiId) {
        self.$router.push({
          name: 'InfoPlace',
          params: {
            poiId: poiId
          }
        })
      }
    },
    onClickTag (tag) {
      const self = this
      self.$router.push({
        name: 'Main',
        query: {
          search: `tag: ${tag}`
        }
      })
    },
    onTouchstartIcon (e) {
      const self = this
      const label = e.target.querySelector('label') || e.target.nextSibling
      const key = label.innerHTML
      if (self.animateLabelTask[key]) {
        clearTimeout(self.animateLabelTask[key])
      }
      label.style.opacity = '1'
      self.animateLabelTask[key] = setTimeout(() => {
        label.style.opacity = '0'
      }, 3500)
    },
    onClickPrevImage (i) {
      const self = this
      if (i - 1 >= 0) {
        self.$emit('showFullscreenImage', i - 1)
      }
    },
    onClickNextImage (i) {
      const self = this
      if (i + 1 < self.imageList.length) {
        self.$emit('showFullscreenImage', i + 1)
      }
    }
  },
  watch: {
    '$route.params.poiId' () {
      const self = this
      self.$nextTick(() => {
        self.getDetail()
      })
    }
  },
  destroyed () {
    const self = this
    window.removeEventListener('resize', self.onWindowResize)
    self.$root.$off('loginComplete', self.onLoginComplete)
    self.$root.$off('loginFormClose', self.onLoginFormClose)
  }
}
</script>

<style lang="scss" scoped>
.place-info {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgb(255, 255, 255);
  z-index: 150;
  overflow: hidden;
  overflow-y: auto;

  .header {
    width: 100%;
    height: 54px;
    background-color: $darkPrimary;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    padding: 6px 54px 6px 54px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background-image: linear-gradient(0deg, $primary, $darkPrimary);
    border-bottom: 4px solid $lightPrimary;

    .title {
      font-family: Prompt;
      color: white;
      font-size: 18px;
      text-align: left;
      width: 100%;
      -webkit-line-clamp: 1;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    button.back {
      padding: 0px;
      position: absolute;
      top: 50%;
      left: 6px;
      transform: translate(0%, -50%);
      border: none;
      background-color: transparent;
      cursor: pointer;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: white;
        font-size: 24px;
      }
    }

    button.toggle-action-list {
      padding: 0px;
      position: absolute;
      top: 50%;
      right: 6px;
      transform: translate(0%, -50%);
      border: none;
      background-color: transparent;
      cursor: pointer;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: white;
        font-size: 24px;
      }
    }
  }

  .action-list {
    position: fixed;
    top: 46px;
    right: 6px;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    overflow: hidden;
    z-index: 1;

    button {
      border: none;
      background-color: white;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 12px;

      i {
        color: $primary;
      }

      span {
        font-family: 'Prompt';
        font-size: 16px;
        padding: 0px 12px;
      }
    }
  }

  .loading-overlay {
    width: 100%;
    height: calc(100% - 54px);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .body {
    width: 100%;
    height: calc(100% - 54px);
    overflow: hidden;
    overflow-y: auto;

    .map-snippet {
      background-size: cover;
      background-position: center;
      cursor: pointer;
    }

    .notice {
      padding: 3px 6px;
      border-radius: 3px;
      font-family: 'Prompt';
      font-size: 14px;
      background-color: $primary;
      color: white;
      margin: 12px 12px 0px 12px;
      text-align: center;

      &.new-status {
        background-color: $tangerine;
      }

      &.obsoleted-status {
        background-color: $red;
      }

      &.deleted-status {
        background-color: $red;
      }

      &.rejected-status {
        background-color: $red;
      }
    }

    .place-name {
      user-select: text;
      -webkit-user-select: text;
      font-family: 'Prompt';
      font-size: 20px;
      color: $darkPrimary;
      padding: 12px 12px 0px 12px;
      font-weight: bold;
    }

    .sub-place-name {
      user-select: text;
      -webkit-user-select: text;
      font-family: 'Prompt';
      font-size: 16px;
      color: $primary;
      padding: 0px 12px 0px 12px;
    }

    .promptpai {
      padding: 0px 12px 12px 12px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    .date {
      font-size: 12px;
      color: $lightGrey;
      padding: 12px;
      text-align: right;
    }

    .field {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 12px 12px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      .field-icon {
        width: 32px;
        height: 32px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: flex-start;
        position: relative;

        i {
          font-size: 24px;
          color: $grey;
        }

        .image {
          background-position: center;
          background-size: contain;
          filter: grayscale(1);
          width: 24px;
          height: 24px;
        }

        label {
          padding: 1px 6px;
          border-radius: 6px;
          background-color: rgba(0, 0, 0, 0.55);
          color: white;
          font-family: 'Prompt';
          font-size: 12px;
          position: absolute;
          top: 100%;
          left: -6px;
          opacity: 0;
          transition: opacity 0.3s ease 0s;
          width: max-content;
          pointer-events: none;
        }
      }

      .content {
        width: calc(100% - 36px);
        font-size: 16px;
        padding-left: 18px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        line-height: 1.6;
        user-select:text;
        -webkit-user-select: text;

        /deep/ * {
          font-size: 16px;
          max-width: 100%;
          user-select:text;
          -webkit-user-select: text;
        }

        /deep/ a {
          color: $primary;
          text-decoration: none;
        }

        .link {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: baseline;

          i {
            font-size: 14px;
            margin-left: 3px;
            color: $primary;
          }
        }

        .contact-remark {
          font-size: 12px;
          color: $lightGrey;
          margin-top: -4px;
        }

        .place-category-icon {
          height: 16px;
          margin-right: 6px;
          line-height: 1;

          img {
            height: 100%;
          }
        }

        .working-hours {
          .day {
            width: 90px;
          }

          .start,
          .end {
            width: 45px;
          }

          .to {
            padding: 0px 12px;
          }
        }

        .bus-list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-bottom: -12px;

          .bus {
            box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
            padding: 2px 6px;
            border-radius: 3px;
            margin: 0px 12px 12px 0px;
            font-family: 'Prompt';
            font-size: 14px;

            &:last-child {
              margin-right: 0px;
            }
          }
        }

        .tag-list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-bottom: -12px;

          .tag {
            box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
            padding: 2px 12px;
            border-radius: 15px;
            margin: 0px 12px 12px 0px;
            font-family: 'Prompt';
            font-size: 14px;
            color: $primary;

            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }

      &.connector-list {
        ul {
          margin: 0px;
          padding: 0px 0px 0px 0px;

          div {
            margin-bottom: 12px;

            &:last-child {
              margin-bottom: 0px;
            }

            .total-available {
              background-color: rgb(5, 200, 155);
              color: white;
              padding: 0px 12px;
              border-radius: 12px;
              margin-right: 12px;
            }
          }
        }
      }

      &.full-description {
        /deep/ h1, /deep/ h1 * {
          font-size: 48px;
        }
        /deep/ h2, /deep/ h2 * {
          font-size: 42px;
        }
        /deep/ h3, /deep/ h3 * {
          font-size: 36px;
        }
        /deep/ h4, /deep/ h4 * {
          font-size: 30px;
        }
        /deep/ h5, /deep/ h5 * {
          font-size: 24px;
        }
        /deep/ h6, /deep/ h6 * {
          font-size: 18px;
        }
      }
    }

    .image-label {
      padding: 18px 12px;
      color: $primary;
      font-family: 'Prompt';
      font-size: 16px;
      align-items: center;

      i {
        color: $primary;
        margin-right: 6px;
      }
    }

    .image-list {
      flex-wrap: wrap;

      .image {
        margin: 0px 0px 12px 12px;
      }
    }
  }

  .loading-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 250;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);

    .error-message {
      font-family: 'Prompt';
      font-size: 16px;
      padding: 12px;
      text-align: center;
      color: rgb(255, 255, 255);
      max-width: 230px;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 6px;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}
</style>
